import Dialog from "@mui/material/Dialog";
import styled from "styled-components";
import { Colors } from "../../../theme/variants";
import ButtonComponent, {
  ButtonState,
  ButtonType,
} from "../../../components/Button/ButtonComponent";
import LoadingLottieJson from "../../../assets/loading.json";
import { useLottie } from "lottie-react";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { spacing } from "@mui/system";
import { TextField as MuiTextField } from "@mui/material";
import { ActionCardProps } from "../../../components/ActionCard/ActionCard";
import { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";

interface DateFilterPopUpProps {
  openDialog: boolean;
  isLoadingDialog?: boolean;
  dates: DateFilterType | undefined;
  onCancelDialogCallback: () => void;
  onConfirmDialogCallback: (action: DateFilterType) => void;
  doNotChangeEndDateWhenChangeBeginDate?: boolean;
}

export interface DateFilterType {
  startDate: Date;
  deadlineDate: Date;
}

const DateFilter: React.FC<DateFilterPopUpProps> = ({
  openDialog,
  isLoadingDialog,
  dates,
  onCancelDialogCallback,
  onConfirmDialogCallback,
  doNotChangeEndDateWhenChangeBeginDate = false,
}) => {
  const options = {
    animationData: LoadingLottieJson,
    loop: true,
    autoplay: true,
    style: { width: 120, height: 120 },
  };
  const { View } = useLottie(options);
  const [beginDate, setBeginDate] = useState<Date | undefined>(
    dates?.startDate
  );
  const [endDate, setEndDate] = useState<Date | undefined>(dates?.deadlineDate);

  useEffect(() => {
    setBeginDate(dates?.startDate);
    setEndDate(dates?.deadlineDate);
  }, [dates]);

  const beginDateOnChange = (date: any) => {
    setBeginDate(date.toDate());
    if (!doNotChangeEndDateWhenChangeBeginDate) {
      const datePlusServenDays = new Date(date.toDate());
      datePlusServenDays.setDate(datePlusServenDays.getDate() + 7);
      setEndDate(datePlusServenDays);
    }
  };

  const endDateOnChange = (date: any) => {
    setEndDate(date.toDate());
  };

  const onConfirmDialog = () => {
    // console.log("config - dialog");
    // console.log("dates - ", dates, beginDate, endDate);
    if (dates && beginDate && endDate) {
      dates.startDate = beginDate;
      dates.deadlineDate = endDate;
      onConfirmDialogCallback(dates);
      return;
    }

    if (beginDate && endDate) {
      onConfirmDialogCallback({ startDate: beginDate, deadlineDate: endDate });
      return;
    }

    onConfirmDialogCallback({
      startDate: new Date(),
      deadlineDate: new Date(),
    });
  };

  const onCancelDialog = () => {
    onCancelDialogCallback();
  };

  return (
    <BootstrapDialog open={openDialog}>
      {!isLoadingDialog ? (
        <DialogContainer>
          <DialogTitle>Filtrar por datas</DialogTitle>

          <DialogDescription></DialogDescription>

          <DatePickerContainer>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              <DesktopDatePicker
                label="Data início"
                value={beginDate}
                onChange={(date) => beginDateOnChange(date)}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
            >
              <DesktopDatePicker
                label="Data fim"
                value={endDate}
                onChange={(date) => endDateOnChange(date)}
                renderInput={(params) => <TextField size="small" {...params} />}
              />
            </LocalizationProvider>
          </DatePickerContainer>

          <ButtonContainer>
            <ButtonComponent
              text="Cancelar"
              type={ButtonType.TRANSPARENT}
              onClick={onCancelDialog}
            />
            <ButtonComponent
              state={ButtonState.DEFAULT_ENABLED}
              text="Confirmar"
              onClick={onConfirmDialog}
            />
          </ButtonContainer>
        </DialogContainer>
      ) : null}
      {isLoadingDialog === true ? (
        <LoadingDialogContainer>{View}</LoadingDialogContainer>
      ) : null}
    </BootstrapDialog>
  );
};

const BootstrapDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 0;
    border-radius: 16px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
`;

const DialogContainer = styled.div`
  display: grid;
  /* grid-template-columns: 1fr;
  grid-template-rows: auto auto; */
  width: 397px;
  height: max-content;
  background: white;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 32px;
  padding-bottom: 24px;
  align-content: start;
`;

const DialogTitle = styled.div`
  color: ${Colors.primaryColor};
  font-size: 24px;
  font-family: "Noto Sans";
  font-weight: 700;

  word-wrap: break-word;
`;

const DialogDescription = styled.div`
  color: ${Colors.primaryColor};
  font-size: 16px;
  font-family: "Noto Sans";
  font-weight: 400;
  word-wrap: break-word;
  margin-top: 16px;
`;

const ButtonContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: end;
  gap: 24px;
  margin-top: 40px;
`;

const LoadingDialogContainer = styled.div`
  display: grid;
  width: 301px;
  height: 216px;
  background: white;
  justify-content: center;
  align-content: center;
`;

const TextField = styled(MuiTextField)<{ my?: number }>(spacing);

const DatePickerContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
  margin-top: 20px;
`;

export default DateFilter;
